html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Open Sans', Helvetica, sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

@font-face {
  font-family: 'Lato';
  src: url('./fonts/lato-regular.woff2') format('woff2');
}

a, a:visited, a:hover, a:active {
  text-decoration: none;
  color: inherit;
}
